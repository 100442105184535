var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.builds
    ? _c(
        "div",
        { staticClass: "droneci-builds-wrapper" },
        _vm._l(_vm.builds, function (build) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.infoTooltip(build),
                  expression: "infoTooltip(build)",
                },
              ],
              key: build.id,
              staticClass: "build-row",
            },
            [
              _c("div", { staticClass: "status" }, [
                _c("p", { class: build.build.status }, [
                  _vm._v(_vm._s(_vm._f("formatStatus")(build.build.status))),
                ]),
                build.build.status == "running"
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("formatTimeAgo")(build.build.started * 1000)
                          ) +
                          " ago "
                      ),
                    ])
                  : build.build.status != "pending"
                  ? _c("span", [
                      _vm._v(
                        " " + _vm._s(_vm.formatBuildDuration(build)) + " "
                      ),
                    ])
                  : _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("formatTimeAgo")(build.build.created * 1000)
                          ) +
                          " ago "
                      ),
                    ]),
              ]),
              _c("div", { staticClass: "info" }, [
                _c("div", { staticClass: "build-name" }, [
                  _vm._v(" " + _vm._s(build.name) + " "),
                  _c(
                    "a",
                    {
                      staticClass: "droneci-build-number",
                      attrs: {
                        href:
                          build.baseurl +
                          "/" +
                          build.slug +
                          "/" +
                          build.build.number,
                        target: "_blank",
                      },
                    },
                    [_vm._v(_vm._s(build.build.number))]
                  ),
                ]),
                _c("div", { staticClass: "build-desc" }, [
                  _c(
                    "span",
                    { staticClass: "droneci-extra" },
                    [
                      build.build.event == "pull_request"
                        ? [
                            _c(
                              "a",
                              {
                                staticClass: "droneci-extra-info",
                                attrs: {
                                  href: build.build.link,
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  "#" + _vm._s(_vm.formatPrId(build.build.link))
                                ),
                              ]
                            ),
                            _vm._v(" to "),
                          ]
                        : build.build.event == "push"
                        ? [
                            _c(
                              "a",
                              {
                                staticClass: "droneci-extra-info",
                                attrs: {
                                  href: build.build.link,
                                  target: "_blank",
                                },
                              },
                              [_vm._v("push")]
                            ),
                            _vm._v(" to "),
                          ]
                        : _vm._e(),
                      _c(
                        "a",
                        {
                          staticClass: "droneci-extra-info",
                          attrs: { href: build.git_http_url, target: "_blank" },
                        },
                        [_vm._v(" " + _vm._s(build.build.target) + " ")]
                      ),
                    ],
                    2
                  ),
                ]),
              ]),
            ]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }